import revive_payload_client_4sVQNw7RlN from "/usr/src/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/src/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/usr/src/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/src/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/usr/src/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_iGLjBbor9q from "/usr/src/app/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import formkitPlugin_pZqjah0RUG from "/usr/src/app/.nuxt/formkitPlugin.mjs";
import inkline_InonNHoW4H from "/usr/src/app/.nuxt/inkline.mjs";
import ssr_plugin_B4ptqVdIfe from "/usr/src/app/node_modules/@vueuse/nuxt/ssr-plugin.mjs";
import sentry_client_shVUlIjFLk from "/usr/src/app/plugins/sentry.client.ts";
import google_data_layer_client_TpniCU7O9T from "/usr/src/app/plugins/analytics/google-data-layer.client.ts";
import facebook_pixel_client_bxG7cA6lDG from "/usr/src/app/plugins/analytics/facebook-pixel.client.ts";
import criteo_client_rpE5mToNpI from "/usr/src/app/plugins/analytics/criteo.client.ts";
import klaviyo_client_kfG2zO22CJ from "/usr/src/app/plugins/analytics/klaviyo.client.ts";
import _01_localization_client_GlDya8E6aw from "/usr/src/app/plugins/01.localization.client.ts";
import live_agent_client_HLIj31A0dg from "/usr/src/app/plugins/live-agent.client.ts";
import router_WMnTGednOQ from "/usr/src/app/plugins/router.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_iGLjBbor9q,
  formkitPlugin_pZqjah0RUG,
  inkline_InonNHoW4H,
  ssr_plugin_B4ptqVdIfe,
  sentry_client_shVUlIjFLk,
  google_data_layer_client_TpniCU7O9T,
  facebook_pixel_client_bxG7cA6lDG,
  criteo_client_rpE5mToNpI,
  klaviyo_client_kfG2zO22CJ,
  _01_localization_client_GlDya8E6aw,
  live_agent_client_HLIj31A0dg,
  router_WMnTGednOQ
]