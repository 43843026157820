export default {
  "calculate-page": () => import("/usr/src/app/layouts/CalculatePage.vue").then(m => m.default || m),
  cart: () => import("/usr/src/app/layouts/Cart.vue").then(m => m.default || m),
  checkout: () => import("/usr/src/app/layouts/Checkout.vue").then(m => m.default || m),
  "create-order": () => import("/usr/src/app/layouts/CreateOrder.vue").then(m => m.default || m),
  "index-page": () => import("/usr/src/app/layouts/IndexPage.vue").then(m => m.default || m),
  "market-page": () => import("/usr/src/app/layouts/MarketPage.vue").then(m => m.default || m),
  page: () => import("/usr/src/app/layouts/Page.vue").then(m => m.default || m),
  payment: () => import("/usr/src/app/layouts/Payment.vue").then(m => m.default || m),
  pro: () => import("/usr/src/app/layouts/Pro.vue").then(m => m.default || m),
  "quote-page": () => import("/usr/src/app/layouts/QuotePage.vue").then(m => m.default || m),
  default: () => import("/usr/src/app/layouts/default.vue").then(m => m.default || m)
}