import { default as _91_46_46_46slug_93pvGVZEAWcBMeta } from "/usr/src/app/pages/[...slug].vue?macro=true";
import { default as _404vPzf1XjGQ3Meta } from "/usr/src/app/pages/404.vue?macro=true";
import { default as about_45usCsa1Q1uHpWMeta } from "/usr/src/app/pages/about-us.vue?macro=true";
import { default as indexp5VnaoBD3PMeta } from "/usr/src/app/pages/account-information/index.vue?macro=true";
import { default as all_45brandseJHe9D9qB5Meta } from "/usr/src/app/pages/all-brands.vue?macro=true";
import { default as _91slug_93lexuceAHwfMeta } from "/usr/src/app/pages/blog/[slug].vue?macro=true";
import { default as indexgewR7USAlvMeta } from "/usr/src/app/pages/blog/index.vue?macro=true";
import { default as _91_46_46_46slug_93PNJ6XebJRUMeta } from "/usr/src/app/pages/c/[...slug].vue?macro=true";
import { default as indexnAn0aWyQk5Meta } from "/usr/src/app/pages/cart/index.vue?macro=true";
import { default as _91_91slug_93_931IkWHvA2PUMeta } from "/usr/src/app/pages/checkout/[[slug]].vue?macro=true";
import { default as indexJPEr6nErnHMeta } from "/usr/src/app/pages/checkout/index.vue?macro=true";
import { default as claim_45form_45proAhG3j2dwGRMeta } from "/usr/src/app/pages/claim-form-pro.vue?macro=true";
import { default as claim_45formH1Ji0jvwY6Meta } from "/usr/src/app/pages/claim-form.vue?macro=true";
import { default as _91_91slug_93_930R1QKLpyjJMeta } from "/usr/src/app/pages/confirm/[[slug]].vue?macro=true";
import { default as indexwcwbaXh9yfMeta } from "/usr/src/app/pages/confirm/index.vue?macro=true";
import { default as indexU0pKTPb09wMeta } from "/usr/src/app/pages/contacts/index.vue?macro=true";
import { default as _91_46_46_46slug_93SPo925NnjtMeta } from "/usr/src/app/pages/create-order/[...slug].vue?macro=true";
import { default as indexvkWUyUQMzRMeta } from "/usr/src/app/pages/dealzone/index.vue?macro=true";
import { default as indexeWtiBsKNVtMeta } from "/usr/src/app/pages/delivery-addresses/index.vue?macro=true";
import { default as _91slug_93YZ7S0Y17VxMeta } from "/usr/src/app/pages/faq/[slug].vue?macro=true";
import { default as indexahXeNAV1CfMeta } from "/usr/src/app/pages/faq/index.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as _91id_93UPycwcyqQEMeta } from "/usr/src/app/pages/invoice/[id].vue?macro=true";
import { default as acceptsH0V90uNdcMeta } from "/usr/src/app/pages/invoice/accept.vue?macro=true";
import { default as declineV2Gl1QmsIbMeta } from "/usr/src/app/pages/invoice/decline.vue?macro=true";
import { default as _91id_93ygkyGwnqjzMeta } from "/usr/src/app/pages/iphone-reservation/confirm/[id].vue?macro=true";
import { default as index8zRF1HOAdRMeta } from "/usr/src/app/pages/iphone-reservation/index.vue?macro=true";
import { default as indexMqL0VaR3piMeta } from "/usr/src/app/pages/login/index.vue?macro=true";
import { default as _91_46_46_46slug_93UJnv01I0NMMeta } from "/usr/src/app/pages/market/product/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93PE1D9ec3hCMeta } from "/usr/src/app/pages/my-orders/[...slug].vue?macro=true";
import { default as indexk4uRh4l1stMeta } from "/usr/src/app/pages/my-orders/index.vue?macro=true";
import { default as _91_46_46_46slug_93adcEiHOZFqMeta } from "/usr/src/app/pages/order-additional-info-needed/[...slug].vue?macro=true";
import { default as indexYF8BEgraPuMeta } from "/usr/src/app/pages/order-form/index.vue?macro=true";
import { default as indexalQh5QzQs5Meta } from "/usr/src/app/pages/order-form/summary/index.vue?macro=true";
import { default as order_45successfulHXQvfD8AYbMeta } from "/usr/src/app/pages/order-successful.vue?macro=true";
import { default as _91_46_46_46slug_93VNZWH4SYNLMeta } from "/usr/src/app/pages/p/[...slug].vue?macro=true";
import { default as _91_46_46_46token_9383FMjFBHTuMeta } from "/usr/src/app/pages/password-reset/[...token].vue?macro=true";
import { default as _91_91slug_93_93qCQwFJKwK5Meta } from "/usr/src/app/pages/payment/[[slug]].vue?macro=true";
import { default as acceptijih8sng7UMeta } from "/usr/src/app/pages/payment/accept.vue?macro=true";
import { default as declinejtAe2p2xuKMeta } from "/usr/src/app/pages/payment/decline.vue?macro=true";
import { default as failzcNT8DRUriMeta } from "/usr/src/app/pages/payment/fail.vue?macro=true";
import { default as indexYKomZRspX9Meta } from "/usr/src/app/pages/payment/index.vue?macro=true";
import { default as indexUqi8FpGrndMeta } from "/usr/src/app/pages/privacy-policy/index.vue?macro=true";
import { default as index0G0XXwWPDNMeta } from "/usr/src/app/pages/pro/index.vue?macro=true";
import { default as indexY0F8ZhxrRUMeta } from "/usr/src/app/pages/purchase-agreement/index.vue?macro=true";
import { default as _91slug_93jKGp6cp1bLMeta } from "/usr/src/app/pages/quotes/[slug].vue?macro=true";
import { default as indexivrkA9wOKvMeta } from "/usr/src/app/pages/quotes/index.vue?macro=true";
import { default as indexPceEpwfy5dMeta } from "/usr/src/app/pages/register/index.vue?macro=true";
import { default as indexMPxiWEXheFMeta } from "/usr/src/app/pages/reservation-policy/index.vue?macro=true";
import { default as successCXY9pRyDQbMeta } from "/usr/src/app/pages/reservation/success.vue?macro=true";
import { default as indexUzt6YGbMnbMeta } from "/usr/src/app/pages/search/index.vue?macro=true";
import { default as index2mBiQ2z58JMeta } from "/usr/src/app/pages/shipzee-pro-delivery/index.vue?macro=true";
import { default as _91_46_46_46id_93IXsRQg9AbnMeta } from "/usr/src/app/pages/shipzee-pro-payment/[...id].vue?macro=true";
import { default as indexTSGf7T3VVGMeta } from "/usr/src/app/pages/shipzee-pro/index.vue?macro=true";
import { default as _91_91slug_93_93vTTqvxdNRtMeta } from "/usr/src/app/pages/success/[[slug]].vue?macro=true";
import { default as indexuC1QZnKMtMMeta } from "/usr/src/app/pages/success/index.vue?macro=true";
import { default as _91id_93oYpswjAvDEMeta } from "/usr/src/app/pages/tracking/[id].vue?macro=true";
import { default as indexwwtlQmCBsHMeta } from "/usr/src/app/pages/tracking/index.vue?macro=true";
import { default as TrackingItems0FY1ZuuTxmMeta } from "/usr/src/app/pages/tracking/TrackingItems.vue?macro=true";
import { default as TrackingItemsJavJOHvUsmUQjMeta } from "/usr/src/app/pages/tracking/TrackingItemsJav.vue?macro=true";
import { default as indexY0bHbiu1TIMeta } from "/usr/src/app/pages/user-account/index.vue?macro=true";
import { default as warrantyp0guaxt5CBMeta } from "/usr/src/app/pages/warranty.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93pvGVZEAWcBMeta?.name ?? "slug",
    path: _91_46_46_46slug_93pvGVZEAWcBMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93pvGVZEAWcBMeta || {},
    alias: _91_46_46_46slug_93pvGVZEAWcBMeta?.alias || [],
    redirect: _91_46_46_46slug_93pvGVZEAWcBMeta?.redirect,
    component: () => import("/usr/src/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _404vPzf1XjGQ3Meta?.name ?? "404",
    path: _404vPzf1XjGQ3Meta?.path ?? "/404",
    meta: _404vPzf1XjGQ3Meta || {},
    alias: _404vPzf1XjGQ3Meta?.alias || [],
    redirect: _404vPzf1XjGQ3Meta?.redirect,
    component: () => import("/usr/src/app/pages/404.vue").then(m => m.default || m)
  },
  {
    name: about_45usCsa1Q1uHpWMeta?.name ?? "about-us",
    path: about_45usCsa1Q1uHpWMeta?.path ?? "/about-us",
    meta: about_45usCsa1Q1uHpWMeta || {},
    alias: about_45usCsa1Q1uHpWMeta?.alias || [],
    redirect: about_45usCsa1Q1uHpWMeta?.redirect,
    component: () => import("/usr/src/app/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: indexp5VnaoBD3PMeta?.name ?? "account-information",
    path: indexp5VnaoBD3PMeta?.path ?? "/account-information",
    meta: indexp5VnaoBD3PMeta || {},
    alias: indexp5VnaoBD3PMeta?.alias || [],
    redirect: indexp5VnaoBD3PMeta?.redirect,
    component: () => import("/usr/src/app/pages/account-information/index.vue").then(m => m.default || m)
  },
  {
    name: all_45brandseJHe9D9qB5Meta?.name ?? "all-brands",
    path: all_45brandseJHe9D9qB5Meta?.path ?? "/all-brands",
    meta: all_45brandseJHe9D9qB5Meta || {},
    alias: all_45brandseJHe9D9qB5Meta?.alias || [],
    redirect: all_45brandseJHe9D9qB5Meta?.redirect,
    component: () => import("/usr/src/app/pages/all-brands.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93lexuceAHwfMeta?.name ?? "blog-slug",
    path: _91slug_93lexuceAHwfMeta?.path ?? "/blog/:slug()",
    meta: _91slug_93lexuceAHwfMeta || {},
    alias: _91slug_93lexuceAHwfMeta?.alias || [],
    redirect: _91slug_93lexuceAHwfMeta?.redirect,
    component: () => import("/usr/src/app/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexgewR7USAlvMeta?.name ?? "blog",
    path: indexgewR7USAlvMeta?.path ?? "/blog",
    meta: indexgewR7USAlvMeta || {},
    alias: indexgewR7USAlvMeta?.alias || [],
    redirect: indexgewR7USAlvMeta?.redirect,
    component: () => import("/usr/src/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93PNJ6XebJRUMeta?.name ?? "c-slug",
    path: _91_46_46_46slug_93PNJ6XebJRUMeta?.path ?? "/c/:slug(.*)*",
    meta: _91_46_46_46slug_93PNJ6XebJRUMeta || {},
    alias: _91_46_46_46slug_93PNJ6XebJRUMeta?.alias || [],
    redirect: _91_46_46_46slug_93PNJ6XebJRUMeta?.redirect,
    component: () => import("/usr/src/app/pages/c/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexnAn0aWyQk5Meta?.name ?? "cart",
    path: indexnAn0aWyQk5Meta?.path ?? "/cart",
    meta: indexnAn0aWyQk5Meta || {},
    alias: indexnAn0aWyQk5Meta?.alias || [],
    redirect: indexnAn0aWyQk5Meta?.redirect,
    component: () => import("/usr/src/app/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_931IkWHvA2PUMeta?.name ?? "checkout-slug",
    path: _91_91slug_93_931IkWHvA2PUMeta?.path ?? "/checkout/:slug?",
    meta: _91_91slug_93_931IkWHvA2PUMeta || {},
    alias: _91_91slug_93_931IkWHvA2PUMeta?.alias || [],
    redirect: _91_91slug_93_931IkWHvA2PUMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: indexJPEr6nErnHMeta?.name ?? "checkout",
    path: indexJPEr6nErnHMeta?.path ?? "/checkout",
    meta: indexJPEr6nErnHMeta || {},
    alias: indexJPEr6nErnHMeta?.alias || [],
    redirect: indexJPEr6nErnHMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: claim_45form_45proAhG3j2dwGRMeta?.name ?? "claim-form-pro",
    path: claim_45form_45proAhG3j2dwGRMeta?.path ?? "/claim-form-pro",
    meta: claim_45form_45proAhG3j2dwGRMeta || {},
    alias: claim_45form_45proAhG3j2dwGRMeta?.alias || [],
    redirect: claim_45form_45proAhG3j2dwGRMeta?.redirect,
    component: () => import("/usr/src/app/pages/claim-form-pro.vue").then(m => m.default || m)
  },
  {
    name: claim_45formH1Ji0jvwY6Meta?.name ?? "claim-form",
    path: claim_45formH1Ji0jvwY6Meta?.path ?? "/claim-form",
    meta: claim_45formH1Ji0jvwY6Meta || {},
    alias: claim_45formH1Ji0jvwY6Meta?.alias || [],
    redirect: claim_45formH1Ji0jvwY6Meta?.redirect,
    component: () => import("/usr/src/app/pages/claim-form.vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_930R1QKLpyjJMeta?.name ?? "confirm-slug",
    path: _91_91slug_93_930R1QKLpyjJMeta?.path ?? "/confirm/:slug?",
    meta: _91_91slug_93_930R1QKLpyjJMeta || {},
    alias: _91_91slug_93_930R1QKLpyjJMeta?.alias || [],
    redirect: _91_91slug_93_930R1QKLpyjJMeta?.redirect,
    component: () => import("/usr/src/app/pages/confirm/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: indexwcwbaXh9yfMeta?.name ?? "confirm",
    path: indexwcwbaXh9yfMeta?.path ?? "/confirm",
    meta: indexwcwbaXh9yfMeta || {},
    alias: indexwcwbaXh9yfMeta?.alias || [],
    redirect: indexwcwbaXh9yfMeta?.redirect,
    component: () => import("/usr/src/app/pages/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: indexU0pKTPb09wMeta?.name ?? "contacts",
    path: indexU0pKTPb09wMeta?.path ?? "/contacts",
    meta: indexU0pKTPb09wMeta || {},
    alias: indexU0pKTPb09wMeta?.alias || [],
    redirect: indexU0pKTPb09wMeta?.redirect,
    component: () => import("/usr/src/app/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93SPo925NnjtMeta?.name ?? "create-order-slug",
    path: _91_46_46_46slug_93SPo925NnjtMeta?.path ?? "/create-order/:slug(.*)*",
    meta: _91_46_46_46slug_93SPo925NnjtMeta || {},
    alias: _91_46_46_46slug_93SPo925NnjtMeta?.alias || [],
    redirect: _91_46_46_46slug_93SPo925NnjtMeta?.redirect,
    component: () => import("/usr/src/app/pages/create-order/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexvkWUyUQMzRMeta?.name ?? "dealzone",
    path: indexvkWUyUQMzRMeta?.path ?? "/dealzone",
    meta: indexvkWUyUQMzRMeta || {},
    alias: indexvkWUyUQMzRMeta?.alias || [],
    redirect: indexvkWUyUQMzRMeta?.redirect,
    component: () => import("/usr/src/app/pages/dealzone/index.vue").then(m => m.default || m)
  },
  {
    name: indexeWtiBsKNVtMeta?.name ?? "delivery-addresses",
    path: indexeWtiBsKNVtMeta?.path ?? "/delivery-addresses",
    meta: indexeWtiBsKNVtMeta || {},
    alias: indexeWtiBsKNVtMeta?.alias || [],
    redirect: indexeWtiBsKNVtMeta?.redirect,
    component: () => import("/usr/src/app/pages/delivery-addresses/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YZ7S0Y17VxMeta?.name ?? "faq-slug",
    path: _91slug_93YZ7S0Y17VxMeta?.path ?? "/faq/:slug()",
    meta: _91slug_93YZ7S0Y17VxMeta || {},
    alias: _91slug_93YZ7S0Y17VxMeta?.alias || [],
    redirect: _91slug_93YZ7S0Y17VxMeta?.redirect,
    component: () => import("/usr/src/app/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexahXeNAV1CfMeta?.name ?? "faq",
    path: indexahXeNAV1CfMeta?.path ?? "/faq",
    meta: indexahXeNAV1CfMeta || {},
    alias: indexahXeNAV1CfMeta?.alias || [],
    redirect: indexahXeNAV1CfMeta?.redirect,
    component: () => import("/usr/src/app/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: indexgw2ikJsyhcMeta?.name ?? "index",
    path: indexgw2ikJsyhcMeta?.path ?? "/",
    meta: indexgw2ikJsyhcMeta || {},
    alias: indexgw2ikJsyhcMeta?.alias || [],
    redirect: indexgw2ikJsyhcMeta?.redirect,
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93UPycwcyqQEMeta?.name ?? "invoice-id",
    path: _91id_93UPycwcyqQEMeta?.path ?? "/invoice/:id()",
    meta: _91id_93UPycwcyqQEMeta || {},
    alias: _91id_93UPycwcyqQEMeta?.alias || [],
    redirect: _91id_93UPycwcyqQEMeta?.redirect,
    component: () => import("/usr/src/app/pages/invoice/[id].vue").then(m => m.default || m)
  },
  {
    name: acceptsH0V90uNdcMeta?.name ?? "invoice-accept",
    path: acceptsH0V90uNdcMeta?.path ?? "/invoice/accept",
    meta: acceptsH0V90uNdcMeta || {},
    alias: acceptsH0V90uNdcMeta?.alias || [],
    redirect: acceptsH0V90uNdcMeta?.redirect,
    component: () => import("/usr/src/app/pages/invoice/accept.vue").then(m => m.default || m)
  },
  {
    name: declineV2Gl1QmsIbMeta?.name ?? "invoice-decline",
    path: declineV2Gl1QmsIbMeta?.path ?? "/invoice/decline",
    meta: declineV2Gl1QmsIbMeta || {},
    alias: declineV2Gl1QmsIbMeta?.alias || [],
    redirect: declineV2Gl1QmsIbMeta?.redirect,
    component: () => import("/usr/src/app/pages/invoice/decline.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ygkyGwnqjzMeta?.name ?? "iphone-reservation-confirm-id",
    path: _91id_93ygkyGwnqjzMeta?.path ?? "/iphone-reservation/confirm/:id()",
    meta: _91id_93ygkyGwnqjzMeta || {},
    alias: _91id_93ygkyGwnqjzMeta?.alias || [],
    redirect: _91id_93ygkyGwnqjzMeta?.redirect,
    component: () => import("/usr/src/app/pages/iphone-reservation/confirm/[id].vue").then(m => m.default || m)
  },
  {
    name: index8zRF1HOAdRMeta?.name ?? "iphone-reservation",
    path: index8zRF1HOAdRMeta?.path ?? "/iphone-reservation",
    meta: index8zRF1HOAdRMeta || {},
    alias: index8zRF1HOAdRMeta?.alias || [],
    redirect: index8zRF1HOAdRMeta?.redirect,
    component: () => import("/usr/src/app/pages/iphone-reservation/index.vue").then(m => m.default || m)
  },
  {
    name: indexMqL0VaR3piMeta?.name ?? "login",
    path: indexMqL0VaR3piMeta?.path ?? "/login",
    meta: indexMqL0VaR3piMeta || {},
    alias: indexMqL0VaR3piMeta?.alias || [],
    redirect: indexMqL0VaR3piMeta?.redirect,
    component: () => import("/usr/src/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93UJnv01I0NMMeta?.name ?? "market-product-slug",
    path: _91_46_46_46slug_93UJnv01I0NMMeta?.path ?? "/market/product/:slug(.*)*",
    meta: _91_46_46_46slug_93UJnv01I0NMMeta || {},
    alias: _91_46_46_46slug_93UJnv01I0NMMeta?.alias || [],
    redirect: _91_46_46_46slug_93UJnv01I0NMMeta?.redirect,
    component: () => import("/usr/src/app/pages/market/product/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93PE1D9ec3hCMeta?.name ?? "my-orders-slug",
    path: _91_46_46_46slug_93PE1D9ec3hCMeta?.path ?? "/my-orders/:slug(.*)*",
    meta: _91_46_46_46slug_93PE1D9ec3hCMeta || {},
    alias: _91_46_46_46slug_93PE1D9ec3hCMeta?.alias || [],
    redirect: _91_46_46_46slug_93PE1D9ec3hCMeta?.redirect,
    component: () => import("/usr/src/app/pages/my-orders/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexk4uRh4l1stMeta?.name ?? "my-orders",
    path: indexk4uRh4l1stMeta?.path ?? "/my-orders",
    meta: indexk4uRh4l1stMeta || {},
    alias: indexk4uRh4l1stMeta?.alias || [],
    redirect: indexk4uRh4l1stMeta?.redirect,
    component: () => import("/usr/src/app/pages/my-orders/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93adcEiHOZFqMeta?.name ?? "order-additional-info-needed-slug",
    path: _91_46_46_46slug_93adcEiHOZFqMeta?.path ?? "/order-additional-info-needed/:slug(.*)*",
    meta: _91_46_46_46slug_93adcEiHOZFqMeta || {},
    alias: _91_46_46_46slug_93adcEiHOZFqMeta?.alias || [],
    redirect: _91_46_46_46slug_93adcEiHOZFqMeta?.redirect,
    component: () => import("/usr/src/app/pages/order-additional-info-needed/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexYF8BEgraPuMeta?.name ?? "order-form",
    path: indexYF8BEgraPuMeta?.path ?? "/order-form",
    meta: indexYF8BEgraPuMeta || {},
    alias: indexYF8BEgraPuMeta?.alias || [],
    redirect: indexYF8BEgraPuMeta?.redirect,
    component: () => import("/usr/src/app/pages/order-form/index.vue").then(m => m.default || m)
  },
  {
    name: indexalQh5QzQs5Meta?.name ?? "order-form-summary",
    path: indexalQh5QzQs5Meta?.path ?? "/order-form/summary",
    meta: indexalQh5QzQs5Meta || {},
    alias: indexalQh5QzQs5Meta?.alias || [],
    redirect: indexalQh5QzQs5Meta?.redirect,
    component: () => import("/usr/src/app/pages/order-form/summary/index.vue").then(m => m.default || m)
  },
  {
    name: order_45successfulHXQvfD8AYbMeta?.name ?? "order-successful",
    path: order_45successfulHXQvfD8AYbMeta?.path ?? "/order-successful",
    meta: order_45successfulHXQvfD8AYbMeta || {},
    alias: order_45successfulHXQvfD8AYbMeta?.alias || [],
    redirect: order_45successfulHXQvfD8AYbMeta?.redirect,
    component: () => import("/usr/src/app/pages/order-successful.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93VNZWH4SYNLMeta?.name ?? "p-slug",
    path: _91_46_46_46slug_93VNZWH4SYNLMeta?.path ?? "/p/:slug(.*)*",
    meta: _91_46_46_46slug_93VNZWH4SYNLMeta || {},
    alias: _91_46_46_46slug_93VNZWH4SYNLMeta?.alias || [],
    redirect: _91_46_46_46slug_93VNZWH4SYNLMeta?.redirect,
    component: () => import("/usr/src/app/pages/p/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46token_9383FMjFBHTuMeta?.name ?? "password-reset-token",
    path: _91_46_46_46token_9383FMjFBHTuMeta?.path ?? "/password-reset/:token(.*)*",
    meta: _91_46_46_46token_9383FMjFBHTuMeta || {},
    alias: _91_46_46_46token_9383FMjFBHTuMeta?.alias || [],
    redirect: _91_46_46_46token_9383FMjFBHTuMeta?.redirect,
    component: () => import("/usr/src/app/pages/password-reset/[...token].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93qCQwFJKwK5Meta?.name ?? "payment-slug",
    path: _91_91slug_93_93qCQwFJKwK5Meta?.path ?? "/payment/:slug?",
    meta: _91_91slug_93_93qCQwFJKwK5Meta || {},
    alias: _91_91slug_93_93qCQwFJKwK5Meta?.alias || [],
    redirect: _91_91slug_93_93qCQwFJKwK5Meta?.redirect,
    component: () => import("/usr/src/app/pages/payment/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: acceptijih8sng7UMeta?.name ?? "payment-accept",
    path: acceptijih8sng7UMeta?.path ?? "/payment/accept",
    meta: acceptijih8sng7UMeta || {},
    alias: acceptijih8sng7UMeta?.alias || [],
    redirect: acceptijih8sng7UMeta?.redirect,
    component: () => import("/usr/src/app/pages/payment/accept.vue").then(m => m.default || m)
  },
  {
    name: declinejtAe2p2xuKMeta?.name ?? "payment-decline",
    path: declinejtAe2p2xuKMeta?.path ?? "/payment/decline",
    meta: declinejtAe2p2xuKMeta || {},
    alias: declinejtAe2p2xuKMeta?.alias || [],
    redirect: declinejtAe2p2xuKMeta?.redirect,
    component: () => import("/usr/src/app/pages/payment/decline.vue").then(m => m.default || m)
  },
  {
    name: failzcNT8DRUriMeta?.name ?? "payment-fail",
    path: failzcNT8DRUriMeta?.path ?? "/payment/fail",
    meta: failzcNT8DRUriMeta || {},
    alias: failzcNT8DRUriMeta?.alias || [],
    redirect: failzcNT8DRUriMeta?.redirect,
    component: () => import("/usr/src/app/pages/payment/fail.vue").then(m => m.default || m)
  },
  {
    name: indexYKomZRspX9Meta?.name ?? "payment",
    path: indexYKomZRspX9Meta?.path ?? "/payment",
    meta: indexYKomZRspX9Meta || {},
    alias: indexYKomZRspX9Meta?.alias || [],
    redirect: indexYKomZRspX9Meta?.redirect,
    component: () => import("/usr/src/app/pages/payment/index.vue").then(m => m.default || m)
  },
  {
    name: indexUqi8FpGrndMeta?.name ?? "privacy-policy",
    path: indexUqi8FpGrndMeta?.path ?? "/privacy-policy",
    meta: indexUqi8FpGrndMeta || {},
    alias: indexUqi8FpGrndMeta?.alias || [],
    redirect: indexUqi8FpGrndMeta?.redirect,
    component: () => import("/usr/src/app/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: index0G0XXwWPDNMeta?.name ?? "pro",
    path: index0G0XXwWPDNMeta?.path ?? "/pro",
    meta: index0G0XXwWPDNMeta || {},
    alias: index0G0XXwWPDNMeta?.alias || [],
    redirect: index0G0XXwWPDNMeta?.redirect,
    component: () => import("/usr/src/app/pages/pro/index.vue").then(m => m.default || m)
  },
  {
    name: indexY0F8ZhxrRUMeta?.name ?? "purchase-agreement",
    path: indexY0F8ZhxrRUMeta?.path ?? "/purchase-agreement",
    meta: indexY0F8ZhxrRUMeta || {},
    alias: indexY0F8ZhxrRUMeta?.alias || [],
    redirect: indexY0F8ZhxrRUMeta?.redirect,
    component: () => import("/usr/src/app/pages/purchase-agreement/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jKGp6cp1bLMeta?.name ?? "quotes-slug",
    path: _91slug_93jKGp6cp1bLMeta?.path ?? "/quotes/:slug()",
    meta: _91slug_93jKGp6cp1bLMeta || {},
    alias: _91slug_93jKGp6cp1bLMeta?.alias || [],
    redirect: _91slug_93jKGp6cp1bLMeta?.redirect,
    component: () => import("/usr/src/app/pages/quotes/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexivrkA9wOKvMeta?.name ?? "quotes",
    path: indexivrkA9wOKvMeta?.path ?? "/quotes",
    meta: indexivrkA9wOKvMeta || {},
    alias: indexivrkA9wOKvMeta?.alias || [],
    redirect: indexivrkA9wOKvMeta?.redirect,
    component: () => import("/usr/src/app/pages/quotes/index.vue").then(m => m.default || m)
  },
  {
    name: indexPceEpwfy5dMeta?.name ?? "register",
    path: indexPceEpwfy5dMeta?.path ?? "/register",
    meta: indexPceEpwfy5dMeta || {},
    alias: indexPceEpwfy5dMeta?.alias || [],
    redirect: indexPceEpwfy5dMeta?.redirect,
    component: () => import("/usr/src/app/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexMPxiWEXheFMeta?.name ?? "reservation-policy",
    path: indexMPxiWEXheFMeta?.path ?? "/reservation-policy",
    meta: indexMPxiWEXheFMeta || {},
    alias: indexMPxiWEXheFMeta?.alias || [],
    redirect: indexMPxiWEXheFMeta?.redirect,
    component: () => import("/usr/src/app/pages/reservation-policy/index.vue").then(m => m.default || m)
  },
  {
    name: successCXY9pRyDQbMeta?.name ?? "reservation-success",
    path: successCXY9pRyDQbMeta?.path ?? "/reservation/success",
    meta: successCXY9pRyDQbMeta || {},
    alias: successCXY9pRyDQbMeta?.alias || [],
    redirect: successCXY9pRyDQbMeta?.redirect,
    component: () => import("/usr/src/app/pages/reservation/success.vue").then(m => m.default || m)
  },
  {
    name: indexUzt6YGbMnbMeta?.name ?? "search",
    path: indexUzt6YGbMnbMeta?.path ?? "/search",
    meta: indexUzt6YGbMnbMeta || {},
    alias: indexUzt6YGbMnbMeta?.alias || [],
    redirect: indexUzt6YGbMnbMeta?.redirect,
    component: () => import("/usr/src/app/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: index2mBiQ2z58JMeta?.name ?? "shipzee-pro-delivery",
    path: index2mBiQ2z58JMeta?.path ?? "/shipzee-pro-delivery",
    meta: index2mBiQ2z58JMeta || {},
    alias: index2mBiQ2z58JMeta?.alias || [],
    redirect: index2mBiQ2z58JMeta?.redirect,
    component: () => import("/usr/src/app/pages/shipzee-pro-delivery/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46id_93IXsRQg9AbnMeta?.name ?? "shipzee-pro-payment-id",
    path: _91_46_46_46id_93IXsRQg9AbnMeta?.path ?? "/shipzee-pro-payment/:id(.*)*",
    meta: _91_46_46_46id_93IXsRQg9AbnMeta || {},
    alias: _91_46_46_46id_93IXsRQg9AbnMeta?.alias || [],
    redirect: _91_46_46_46id_93IXsRQg9AbnMeta?.redirect,
    component: () => import("/usr/src/app/pages/shipzee-pro-payment/[...id].vue").then(m => m.default || m)
  },
  {
    name: indexTSGf7T3VVGMeta?.name ?? "shipzee-pro",
    path: indexTSGf7T3VVGMeta?.path ?? "/shipzee-pro",
    meta: indexTSGf7T3VVGMeta || {},
    alias: indexTSGf7T3VVGMeta?.alias || [],
    redirect: indexTSGf7T3VVGMeta?.redirect,
    component: () => import("/usr/src/app/pages/shipzee-pro/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93vTTqvxdNRtMeta?.name ?? "success-slug",
    path: _91_91slug_93_93vTTqvxdNRtMeta?.path ?? "/success/:slug?",
    meta: _91_91slug_93_93vTTqvxdNRtMeta || {},
    alias: _91_91slug_93_93vTTqvxdNRtMeta?.alias || [],
    redirect: _91_91slug_93_93vTTqvxdNRtMeta?.redirect,
    component: () => import("/usr/src/app/pages/success/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: indexuC1QZnKMtMMeta?.name ?? "success",
    path: indexuC1QZnKMtMMeta?.path ?? "/success",
    meta: indexuC1QZnKMtMMeta || {},
    alias: indexuC1QZnKMtMMeta?.alias || [],
    redirect: indexuC1QZnKMtMMeta?.redirect,
    component: () => import("/usr/src/app/pages/success/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93oYpswjAvDEMeta?.name ?? "tracking-id",
    path: _91id_93oYpswjAvDEMeta?.path ?? "/tracking/:id()",
    meta: _91id_93oYpswjAvDEMeta || {},
    alias: _91id_93oYpswjAvDEMeta?.alias || [],
    redirect: _91id_93oYpswjAvDEMeta?.redirect,
    component: () => import("/usr/src/app/pages/tracking/[id].vue").then(m => m.default || m)
  },
  {
    name: indexwwtlQmCBsHMeta?.name ?? "tracking",
    path: indexwwtlQmCBsHMeta?.path ?? "/tracking",
    meta: indexwwtlQmCBsHMeta || {},
    alias: indexwwtlQmCBsHMeta?.alias || [],
    redirect: indexwwtlQmCBsHMeta?.redirect,
    component: () => import("/usr/src/app/pages/tracking/index.vue").then(m => m.default || m)
  },
  {
    name: TrackingItems0FY1ZuuTxmMeta?.name ?? "tracking-TrackingItems",
    path: TrackingItems0FY1ZuuTxmMeta?.path ?? "/tracking/TrackingItems",
    meta: TrackingItems0FY1ZuuTxmMeta || {},
    alias: TrackingItems0FY1ZuuTxmMeta?.alias || [],
    redirect: TrackingItems0FY1ZuuTxmMeta?.redirect,
    component: () => import("/usr/src/app/pages/tracking/TrackingItems.vue").then(m => m.default || m)
  },
  {
    name: TrackingItemsJavJOHvUsmUQjMeta?.name ?? "tracking-TrackingItemsJav",
    path: TrackingItemsJavJOHvUsmUQjMeta?.path ?? "/tracking/TrackingItemsJav",
    meta: TrackingItemsJavJOHvUsmUQjMeta || {},
    alias: TrackingItemsJavJOHvUsmUQjMeta?.alias || [],
    redirect: TrackingItemsJavJOHvUsmUQjMeta?.redirect,
    component: () => import("/usr/src/app/pages/tracking/TrackingItemsJav.vue").then(m => m.default || m)
  },
  {
    name: indexY0bHbiu1TIMeta?.name ?? "user-account",
    path: indexY0bHbiu1TIMeta?.path ?? "/user-account",
    meta: indexY0bHbiu1TIMeta || {},
    alias: indexY0bHbiu1TIMeta?.alias || [],
    redirect: indexY0bHbiu1TIMeta?.redirect,
    component: () => import("/usr/src/app/pages/user-account/index.vue").then(m => m.default || m)
  },
  {
    name: warrantyp0guaxt5CBMeta?.name ?? "warranty",
    path: warrantyp0guaxt5CBMeta?.path ?? "/warranty",
    meta: warrantyp0guaxt5CBMeta || {},
    alias: warrantyp0guaxt5CBMeta?.alias || [],
    redirect: warrantyp0guaxt5CBMeta?.redirect,
    component: () => import("/usr/src/app/pages/warranty.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93pvGVZEAWcBMeta?.name ?? "locale-slug",
    path: _91_46_46_46slug_93pvGVZEAWcBMeta?.path ?? "/:locale/:slug(.*)*",
    meta: _91_46_46_46slug_93pvGVZEAWcBMeta || {},
    alias: _91_46_46_46slug_93pvGVZEAWcBMeta?.alias || [],
    redirect: _91_46_46_46slug_93pvGVZEAWcBMeta?.redirect,
    component: () => import("/usr/src/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _404vPzf1XjGQ3Meta?.name ?? "locale-404",
    path: _404vPzf1XjGQ3Meta?.path ?? "/:locale/404",
    meta: _404vPzf1XjGQ3Meta || {},
    alias: _404vPzf1XjGQ3Meta?.alias || [],
    redirect: _404vPzf1XjGQ3Meta?.redirect,
    component: () => import("/usr/src/app/pages/404.vue").then(m => m.default || m)
  },
  {
    name: about_45usCsa1Q1uHpWMeta?.name ?? "locale-about-us",
    path: about_45usCsa1Q1uHpWMeta?.path ?? "/:locale/about-us",
    meta: about_45usCsa1Q1uHpWMeta || {},
    alias: about_45usCsa1Q1uHpWMeta?.alias || [],
    redirect: about_45usCsa1Q1uHpWMeta?.redirect,
    component: () => import("/usr/src/app/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: indexp5VnaoBD3PMeta?.name ?? "locale-account-information",
    path: indexp5VnaoBD3PMeta?.path ?? "/:locale/account-information",
    meta: indexp5VnaoBD3PMeta || {},
    alias: indexp5VnaoBD3PMeta?.alias || [],
    redirect: indexp5VnaoBD3PMeta?.redirect,
    component: () => import("/usr/src/app/pages/account-information/index.vue").then(m => m.default || m)
  },
  {
    name: all_45brandseJHe9D9qB5Meta?.name ?? "locale-all-brands",
    path: all_45brandseJHe9D9qB5Meta?.path ?? "/:locale/all-brands",
    meta: all_45brandseJHe9D9qB5Meta || {},
    alias: all_45brandseJHe9D9qB5Meta?.alias || [],
    redirect: all_45brandseJHe9D9qB5Meta?.redirect,
    component: () => import("/usr/src/app/pages/all-brands.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93lexuceAHwfMeta?.name ?? "locale-blog-slug",
    path: _91slug_93lexuceAHwfMeta?.path ?? "/:locale/blog/:slug()",
    meta: _91slug_93lexuceAHwfMeta || {},
    alias: _91slug_93lexuceAHwfMeta?.alias || [],
    redirect: _91slug_93lexuceAHwfMeta?.redirect,
    component: () => import("/usr/src/app/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexgewR7USAlvMeta?.name ?? "locale-blog",
    path: indexgewR7USAlvMeta?.path ?? "/:locale/blog",
    meta: indexgewR7USAlvMeta || {},
    alias: indexgewR7USAlvMeta?.alias || [],
    redirect: indexgewR7USAlvMeta?.redirect,
    component: () => import("/usr/src/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93PNJ6XebJRUMeta?.name ?? "locale-c-slug",
    path: _91_46_46_46slug_93PNJ6XebJRUMeta?.path ?? "/:locale/c/:slug(.*)*",
    meta: _91_46_46_46slug_93PNJ6XebJRUMeta || {},
    alias: _91_46_46_46slug_93PNJ6XebJRUMeta?.alias || [],
    redirect: _91_46_46_46slug_93PNJ6XebJRUMeta?.redirect,
    component: () => import("/usr/src/app/pages/c/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexnAn0aWyQk5Meta?.name ?? "locale-cart",
    path: indexnAn0aWyQk5Meta?.path ?? "/:locale/cart",
    meta: indexnAn0aWyQk5Meta || {},
    alias: indexnAn0aWyQk5Meta?.alias || [],
    redirect: indexnAn0aWyQk5Meta?.redirect,
    component: () => import("/usr/src/app/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_931IkWHvA2PUMeta?.name ?? "locale-checkout-slug",
    path: _91_91slug_93_931IkWHvA2PUMeta?.path ?? "/:locale/checkout/:slug?",
    meta: _91_91slug_93_931IkWHvA2PUMeta || {},
    alias: _91_91slug_93_931IkWHvA2PUMeta?.alias || [],
    redirect: _91_91slug_93_931IkWHvA2PUMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: indexJPEr6nErnHMeta?.name ?? "locale-checkout",
    path: indexJPEr6nErnHMeta?.path ?? "/:locale/checkout",
    meta: indexJPEr6nErnHMeta || {},
    alias: indexJPEr6nErnHMeta?.alias || [],
    redirect: indexJPEr6nErnHMeta?.redirect,
    component: () => import("/usr/src/app/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: claim_45form_45proAhG3j2dwGRMeta?.name ?? "locale-claim-form-pro",
    path: claim_45form_45proAhG3j2dwGRMeta?.path ?? "/:locale/claim-form-pro",
    meta: claim_45form_45proAhG3j2dwGRMeta || {},
    alias: claim_45form_45proAhG3j2dwGRMeta?.alias || [],
    redirect: claim_45form_45proAhG3j2dwGRMeta?.redirect,
    component: () => import("/usr/src/app/pages/claim-form-pro.vue").then(m => m.default || m)
  },
  {
    name: claim_45formH1Ji0jvwY6Meta?.name ?? "locale-claim-form",
    path: claim_45formH1Ji0jvwY6Meta?.path ?? "/:locale/claim-form",
    meta: claim_45formH1Ji0jvwY6Meta || {},
    alias: claim_45formH1Ji0jvwY6Meta?.alias || [],
    redirect: claim_45formH1Ji0jvwY6Meta?.redirect,
    component: () => import("/usr/src/app/pages/claim-form.vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_930R1QKLpyjJMeta?.name ?? "locale-confirm-slug",
    path: _91_91slug_93_930R1QKLpyjJMeta?.path ?? "/:locale/confirm/:slug?",
    meta: _91_91slug_93_930R1QKLpyjJMeta || {},
    alias: _91_91slug_93_930R1QKLpyjJMeta?.alias || [],
    redirect: _91_91slug_93_930R1QKLpyjJMeta?.redirect,
    component: () => import("/usr/src/app/pages/confirm/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: indexwcwbaXh9yfMeta?.name ?? "locale-confirm",
    path: indexwcwbaXh9yfMeta?.path ?? "/:locale/confirm",
    meta: indexwcwbaXh9yfMeta || {},
    alias: indexwcwbaXh9yfMeta?.alias || [],
    redirect: indexwcwbaXh9yfMeta?.redirect,
    component: () => import("/usr/src/app/pages/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: indexU0pKTPb09wMeta?.name ?? "locale-contacts",
    path: indexU0pKTPb09wMeta?.path ?? "/:locale/contacts",
    meta: indexU0pKTPb09wMeta || {},
    alias: indexU0pKTPb09wMeta?.alias || [],
    redirect: indexU0pKTPb09wMeta?.redirect,
    component: () => import("/usr/src/app/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93SPo925NnjtMeta?.name ?? "locale-create-order-slug",
    path: _91_46_46_46slug_93SPo925NnjtMeta?.path ?? "/:locale/create-order/:slug(.*)*",
    meta: _91_46_46_46slug_93SPo925NnjtMeta || {},
    alias: _91_46_46_46slug_93SPo925NnjtMeta?.alias || [],
    redirect: _91_46_46_46slug_93SPo925NnjtMeta?.redirect,
    component: () => import("/usr/src/app/pages/create-order/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexvkWUyUQMzRMeta?.name ?? "locale-dealzone",
    path: indexvkWUyUQMzRMeta?.path ?? "/:locale/dealzone",
    meta: indexvkWUyUQMzRMeta || {},
    alias: indexvkWUyUQMzRMeta?.alias || [],
    redirect: indexvkWUyUQMzRMeta?.redirect,
    component: () => import("/usr/src/app/pages/dealzone/index.vue").then(m => m.default || m)
  },
  {
    name: indexeWtiBsKNVtMeta?.name ?? "locale-delivery-addresses",
    path: indexeWtiBsKNVtMeta?.path ?? "/:locale/delivery-addresses",
    meta: indexeWtiBsKNVtMeta || {},
    alias: indexeWtiBsKNVtMeta?.alias || [],
    redirect: indexeWtiBsKNVtMeta?.redirect,
    component: () => import("/usr/src/app/pages/delivery-addresses/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YZ7S0Y17VxMeta?.name ?? "locale-faq-slug",
    path: _91slug_93YZ7S0Y17VxMeta?.path ?? "/:locale/faq/:slug()",
    meta: _91slug_93YZ7S0Y17VxMeta || {},
    alias: _91slug_93YZ7S0Y17VxMeta?.alias || [],
    redirect: _91slug_93YZ7S0Y17VxMeta?.redirect,
    component: () => import("/usr/src/app/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexahXeNAV1CfMeta?.name ?? "locale-faq",
    path: indexahXeNAV1CfMeta?.path ?? "/:locale/faq",
    meta: indexahXeNAV1CfMeta || {},
    alias: indexahXeNAV1CfMeta?.alias || [],
    redirect: indexahXeNAV1CfMeta?.redirect,
    component: () => import("/usr/src/app/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: indexgw2ikJsyhcMeta?.name ?? "locale-index",
    path: indexgw2ikJsyhcMeta?.path ?? "/:locale/",
    meta: indexgw2ikJsyhcMeta || {},
    alias: indexgw2ikJsyhcMeta?.alias || [],
    redirect: indexgw2ikJsyhcMeta?.redirect,
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93UPycwcyqQEMeta?.name ?? "locale-invoice-id",
    path: _91id_93UPycwcyqQEMeta?.path ?? "/:locale/invoice/:id()",
    meta: _91id_93UPycwcyqQEMeta || {},
    alias: _91id_93UPycwcyqQEMeta?.alias || [],
    redirect: _91id_93UPycwcyqQEMeta?.redirect,
    component: () => import("/usr/src/app/pages/invoice/[id].vue").then(m => m.default || m)
  },
  {
    name: acceptsH0V90uNdcMeta?.name ?? "locale-invoice-accept",
    path: acceptsH0V90uNdcMeta?.path ?? "/:locale/invoice/accept",
    meta: acceptsH0V90uNdcMeta || {},
    alias: acceptsH0V90uNdcMeta?.alias || [],
    redirect: acceptsH0V90uNdcMeta?.redirect,
    component: () => import("/usr/src/app/pages/invoice/accept.vue").then(m => m.default || m)
  },
  {
    name: declineV2Gl1QmsIbMeta?.name ?? "locale-invoice-decline",
    path: declineV2Gl1QmsIbMeta?.path ?? "/:locale/invoice/decline",
    meta: declineV2Gl1QmsIbMeta || {},
    alias: declineV2Gl1QmsIbMeta?.alias || [],
    redirect: declineV2Gl1QmsIbMeta?.redirect,
    component: () => import("/usr/src/app/pages/invoice/decline.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ygkyGwnqjzMeta?.name ?? "locale-iphone-reservation-confirm-id",
    path: _91id_93ygkyGwnqjzMeta?.path ?? "/:locale/iphone-reservation/confirm/:id()",
    meta: _91id_93ygkyGwnqjzMeta || {},
    alias: _91id_93ygkyGwnqjzMeta?.alias || [],
    redirect: _91id_93ygkyGwnqjzMeta?.redirect,
    component: () => import("/usr/src/app/pages/iphone-reservation/confirm/[id].vue").then(m => m.default || m)
  },
  {
    name: index8zRF1HOAdRMeta?.name ?? "locale-iphone-reservation",
    path: index8zRF1HOAdRMeta?.path ?? "/:locale/iphone-reservation",
    meta: index8zRF1HOAdRMeta || {},
    alias: index8zRF1HOAdRMeta?.alias || [],
    redirect: index8zRF1HOAdRMeta?.redirect,
    component: () => import("/usr/src/app/pages/iphone-reservation/index.vue").then(m => m.default || m)
  },
  {
    name: indexMqL0VaR3piMeta?.name ?? "locale-login",
    path: indexMqL0VaR3piMeta?.path ?? "/:locale/login",
    meta: indexMqL0VaR3piMeta || {},
    alias: indexMqL0VaR3piMeta?.alias || [],
    redirect: indexMqL0VaR3piMeta?.redirect,
    component: () => import("/usr/src/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93UJnv01I0NMMeta?.name ?? "locale-market-product-slug",
    path: _91_46_46_46slug_93UJnv01I0NMMeta?.path ?? "/:locale/market/product/:slug(.*)*",
    meta: _91_46_46_46slug_93UJnv01I0NMMeta || {},
    alias: _91_46_46_46slug_93UJnv01I0NMMeta?.alias || [],
    redirect: _91_46_46_46slug_93UJnv01I0NMMeta?.redirect,
    component: () => import("/usr/src/app/pages/market/product/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93PE1D9ec3hCMeta?.name ?? "locale-my-orders-slug",
    path: _91_46_46_46slug_93PE1D9ec3hCMeta?.path ?? "/:locale/my-orders/:slug(.*)*",
    meta: _91_46_46_46slug_93PE1D9ec3hCMeta || {},
    alias: _91_46_46_46slug_93PE1D9ec3hCMeta?.alias || [],
    redirect: _91_46_46_46slug_93PE1D9ec3hCMeta?.redirect,
    component: () => import("/usr/src/app/pages/my-orders/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexk4uRh4l1stMeta?.name ?? "locale-my-orders",
    path: indexk4uRh4l1stMeta?.path ?? "/:locale/my-orders",
    meta: indexk4uRh4l1stMeta || {},
    alias: indexk4uRh4l1stMeta?.alias || [],
    redirect: indexk4uRh4l1stMeta?.redirect,
    component: () => import("/usr/src/app/pages/my-orders/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93adcEiHOZFqMeta?.name ?? "locale-order-additional-info-needed-slug",
    path: _91_46_46_46slug_93adcEiHOZFqMeta?.path ?? "/:locale/order-additional-info-needed/:slug(.*)*",
    meta: _91_46_46_46slug_93adcEiHOZFqMeta || {},
    alias: _91_46_46_46slug_93adcEiHOZFqMeta?.alias || [],
    redirect: _91_46_46_46slug_93adcEiHOZFqMeta?.redirect,
    component: () => import("/usr/src/app/pages/order-additional-info-needed/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexYF8BEgraPuMeta?.name ?? "locale-order-form",
    path: indexYF8BEgraPuMeta?.path ?? "/:locale/order-form",
    meta: indexYF8BEgraPuMeta || {},
    alias: indexYF8BEgraPuMeta?.alias || [],
    redirect: indexYF8BEgraPuMeta?.redirect,
    component: () => import("/usr/src/app/pages/order-form/index.vue").then(m => m.default || m)
  },
  {
    name: indexalQh5QzQs5Meta?.name ?? "locale-order-form-summary",
    path: indexalQh5QzQs5Meta?.path ?? "/:locale/order-form/summary",
    meta: indexalQh5QzQs5Meta || {},
    alias: indexalQh5QzQs5Meta?.alias || [],
    redirect: indexalQh5QzQs5Meta?.redirect,
    component: () => import("/usr/src/app/pages/order-form/summary/index.vue").then(m => m.default || m)
  },
  {
    name: order_45successfulHXQvfD8AYbMeta?.name ?? "locale-order-successful",
    path: order_45successfulHXQvfD8AYbMeta?.path ?? "/:locale/order-successful",
    meta: order_45successfulHXQvfD8AYbMeta || {},
    alias: order_45successfulHXQvfD8AYbMeta?.alias || [],
    redirect: order_45successfulHXQvfD8AYbMeta?.redirect,
    component: () => import("/usr/src/app/pages/order-successful.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93VNZWH4SYNLMeta?.name ?? "locale-p-slug",
    path: _91_46_46_46slug_93VNZWH4SYNLMeta?.path ?? "/:locale/p/:slug(.*)*",
    meta: _91_46_46_46slug_93VNZWH4SYNLMeta || {},
    alias: _91_46_46_46slug_93VNZWH4SYNLMeta?.alias || [],
    redirect: _91_46_46_46slug_93VNZWH4SYNLMeta?.redirect,
    component: () => import("/usr/src/app/pages/p/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46token_9383FMjFBHTuMeta?.name ?? "locale-password-reset-token",
    path: _91_46_46_46token_9383FMjFBHTuMeta?.path ?? "/:locale/password-reset/:token(.*)*",
    meta: _91_46_46_46token_9383FMjFBHTuMeta || {},
    alias: _91_46_46_46token_9383FMjFBHTuMeta?.alias || [],
    redirect: _91_46_46_46token_9383FMjFBHTuMeta?.redirect,
    component: () => import("/usr/src/app/pages/password-reset/[...token].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93qCQwFJKwK5Meta?.name ?? "locale-payment-slug",
    path: _91_91slug_93_93qCQwFJKwK5Meta?.path ?? "/:locale/payment/:slug?",
    meta: _91_91slug_93_93qCQwFJKwK5Meta || {},
    alias: _91_91slug_93_93qCQwFJKwK5Meta?.alias || [],
    redirect: _91_91slug_93_93qCQwFJKwK5Meta?.redirect,
    component: () => import("/usr/src/app/pages/payment/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: acceptijih8sng7UMeta?.name ?? "locale-payment-accept",
    path: acceptijih8sng7UMeta?.path ?? "/:locale/payment/accept",
    meta: acceptijih8sng7UMeta || {},
    alias: acceptijih8sng7UMeta?.alias || [],
    redirect: acceptijih8sng7UMeta?.redirect,
    component: () => import("/usr/src/app/pages/payment/accept.vue").then(m => m.default || m)
  },
  {
    name: declinejtAe2p2xuKMeta?.name ?? "locale-payment-decline",
    path: declinejtAe2p2xuKMeta?.path ?? "/:locale/payment/decline",
    meta: declinejtAe2p2xuKMeta || {},
    alias: declinejtAe2p2xuKMeta?.alias || [],
    redirect: declinejtAe2p2xuKMeta?.redirect,
    component: () => import("/usr/src/app/pages/payment/decline.vue").then(m => m.default || m)
  },
  {
    name: failzcNT8DRUriMeta?.name ?? "locale-payment-fail",
    path: failzcNT8DRUriMeta?.path ?? "/:locale/payment/fail",
    meta: failzcNT8DRUriMeta || {},
    alias: failzcNT8DRUriMeta?.alias || [],
    redirect: failzcNT8DRUriMeta?.redirect,
    component: () => import("/usr/src/app/pages/payment/fail.vue").then(m => m.default || m)
  },
  {
    name: indexYKomZRspX9Meta?.name ?? "locale-payment",
    path: indexYKomZRspX9Meta?.path ?? "/:locale/payment",
    meta: indexYKomZRspX9Meta || {},
    alias: indexYKomZRspX9Meta?.alias || [],
    redirect: indexYKomZRspX9Meta?.redirect,
    component: () => import("/usr/src/app/pages/payment/index.vue").then(m => m.default || m)
  },
  {
    name: indexUqi8FpGrndMeta?.name ?? "locale-privacy-policy",
    path: indexUqi8FpGrndMeta?.path ?? "/:locale/privacy-policy",
    meta: indexUqi8FpGrndMeta || {},
    alias: indexUqi8FpGrndMeta?.alias || [],
    redirect: indexUqi8FpGrndMeta?.redirect,
    component: () => import("/usr/src/app/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: index0G0XXwWPDNMeta?.name ?? "locale-pro",
    path: index0G0XXwWPDNMeta?.path ?? "/:locale/pro",
    meta: index0G0XXwWPDNMeta || {},
    alias: index0G0XXwWPDNMeta?.alias || [],
    redirect: index0G0XXwWPDNMeta?.redirect,
    component: () => import("/usr/src/app/pages/pro/index.vue").then(m => m.default || m)
  },
  {
    name: indexY0F8ZhxrRUMeta?.name ?? "locale-purchase-agreement",
    path: indexY0F8ZhxrRUMeta?.path ?? "/:locale/purchase-agreement",
    meta: indexY0F8ZhxrRUMeta || {},
    alias: indexY0F8ZhxrRUMeta?.alias || [],
    redirect: indexY0F8ZhxrRUMeta?.redirect,
    component: () => import("/usr/src/app/pages/purchase-agreement/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jKGp6cp1bLMeta?.name ?? "locale-quotes-slug",
    path: _91slug_93jKGp6cp1bLMeta?.path ?? "/:locale/quotes/:slug()",
    meta: _91slug_93jKGp6cp1bLMeta || {},
    alias: _91slug_93jKGp6cp1bLMeta?.alias || [],
    redirect: _91slug_93jKGp6cp1bLMeta?.redirect,
    component: () => import("/usr/src/app/pages/quotes/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexivrkA9wOKvMeta?.name ?? "locale-quotes",
    path: indexivrkA9wOKvMeta?.path ?? "/:locale/quotes",
    meta: indexivrkA9wOKvMeta || {},
    alias: indexivrkA9wOKvMeta?.alias || [],
    redirect: indexivrkA9wOKvMeta?.redirect,
    component: () => import("/usr/src/app/pages/quotes/index.vue").then(m => m.default || m)
  },
  {
    name: indexPceEpwfy5dMeta?.name ?? "locale-register",
    path: indexPceEpwfy5dMeta?.path ?? "/:locale/register",
    meta: indexPceEpwfy5dMeta || {},
    alias: indexPceEpwfy5dMeta?.alias || [],
    redirect: indexPceEpwfy5dMeta?.redirect,
    component: () => import("/usr/src/app/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexMPxiWEXheFMeta?.name ?? "locale-reservation-policy",
    path: indexMPxiWEXheFMeta?.path ?? "/:locale/reservation-policy",
    meta: indexMPxiWEXheFMeta || {},
    alias: indexMPxiWEXheFMeta?.alias || [],
    redirect: indexMPxiWEXheFMeta?.redirect,
    component: () => import("/usr/src/app/pages/reservation-policy/index.vue").then(m => m.default || m)
  },
  {
    name: successCXY9pRyDQbMeta?.name ?? "locale-reservation-success",
    path: successCXY9pRyDQbMeta?.path ?? "/:locale/reservation/success",
    meta: successCXY9pRyDQbMeta || {},
    alias: successCXY9pRyDQbMeta?.alias || [],
    redirect: successCXY9pRyDQbMeta?.redirect,
    component: () => import("/usr/src/app/pages/reservation/success.vue").then(m => m.default || m)
  },
  {
    name: indexUzt6YGbMnbMeta?.name ?? "locale-search",
    path: indexUzt6YGbMnbMeta?.path ?? "/:locale/search",
    meta: indexUzt6YGbMnbMeta || {},
    alias: indexUzt6YGbMnbMeta?.alias || [],
    redirect: indexUzt6YGbMnbMeta?.redirect,
    component: () => import("/usr/src/app/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: index2mBiQ2z58JMeta?.name ?? "locale-shipzee-pro-delivery",
    path: index2mBiQ2z58JMeta?.path ?? "/:locale/shipzee-pro-delivery",
    meta: index2mBiQ2z58JMeta || {},
    alias: index2mBiQ2z58JMeta?.alias || [],
    redirect: index2mBiQ2z58JMeta?.redirect,
    component: () => import("/usr/src/app/pages/shipzee-pro-delivery/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46id_93IXsRQg9AbnMeta?.name ?? "locale-shipzee-pro-payment-id",
    path: _91_46_46_46id_93IXsRQg9AbnMeta?.path ?? "/:locale/shipzee-pro-payment/:id(.*)*",
    meta: _91_46_46_46id_93IXsRQg9AbnMeta || {},
    alias: _91_46_46_46id_93IXsRQg9AbnMeta?.alias || [],
    redirect: _91_46_46_46id_93IXsRQg9AbnMeta?.redirect,
    component: () => import("/usr/src/app/pages/shipzee-pro-payment/[...id].vue").then(m => m.default || m)
  },
  {
    name: indexTSGf7T3VVGMeta?.name ?? "locale-shipzee-pro",
    path: indexTSGf7T3VVGMeta?.path ?? "/:locale/shipzee-pro",
    meta: indexTSGf7T3VVGMeta || {},
    alias: indexTSGf7T3VVGMeta?.alias || [],
    redirect: indexTSGf7T3VVGMeta?.redirect,
    component: () => import("/usr/src/app/pages/shipzee-pro/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93vTTqvxdNRtMeta?.name ?? "locale-success-slug",
    path: _91_91slug_93_93vTTqvxdNRtMeta?.path ?? "/:locale/success/:slug?",
    meta: _91_91slug_93_93vTTqvxdNRtMeta || {},
    alias: _91_91slug_93_93vTTqvxdNRtMeta?.alias || [],
    redirect: _91_91slug_93_93vTTqvxdNRtMeta?.redirect,
    component: () => import("/usr/src/app/pages/success/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: indexuC1QZnKMtMMeta?.name ?? "locale-success",
    path: indexuC1QZnKMtMMeta?.path ?? "/:locale/success",
    meta: indexuC1QZnKMtMMeta || {},
    alias: indexuC1QZnKMtMMeta?.alias || [],
    redirect: indexuC1QZnKMtMMeta?.redirect,
    component: () => import("/usr/src/app/pages/success/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93oYpswjAvDEMeta?.name ?? "locale-tracking-id",
    path: _91id_93oYpswjAvDEMeta?.path ?? "/:locale/tracking/:id()",
    meta: _91id_93oYpswjAvDEMeta || {},
    alias: _91id_93oYpswjAvDEMeta?.alias || [],
    redirect: _91id_93oYpswjAvDEMeta?.redirect,
    component: () => import("/usr/src/app/pages/tracking/[id].vue").then(m => m.default || m)
  },
  {
    name: indexwwtlQmCBsHMeta?.name ?? "locale-tracking",
    path: indexwwtlQmCBsHMeta?.path ?? "/:locale/tracking",
    meta: indexwwtlQmCBsHMeta || {},
    alias: indexwwtlQmCBsHMeta?.alias || [],
    redirect: indexwwtlQmCBsHMeta?.redirect,
    component: () => import("/usr/src/app/pages/tracking/index.vue").then(m => m.default || m)
  },
  {
    name: TrackingItems0FY1ZuuTxmMeta?.name ?? "locale-tracking-TrackingItems",
    path: TrackingItems0FY1ZuuTxmMeta?.path ?? "/:locale/tracking/TrackingItems",
    meta: TrackingItems0FY1ZuuTxmMeta || {},
    alias: TrackingItems0FY1ZuuTxmMeta?.alias || [],
    redirect: TrackingItems0FY1ZuuTxmMeta?.redirect,
    component: () => import("/usr/src/app/pages/tracking/TrackingItems.vue").then(m => m.default || m)
  },
  {
    name: TrackingItemsJavJOHvUsmUQjMeta?.name ?? "locale-tracking-TrackingItemsJav",
    path: TrackingItemsJavJOHvUsmUQjMeta?.path ?? "/:locale/tracking/TrackingItemsJav",
    meta: TrackingItemsJavJOHvUsmUQjMeta || {},
    alias: TrackingItemsJavJOHvUsmUQjMeta?.alias || [],
    redirect: TrackingItemsJavJOHvUsmUQjMeta?.redirect,
    component: () => import("/usr/src/app/pages/tracking/TrackingItemsJav.vue").then(m => m.default || m)
  },
  {
    name: indexY0bHbiu1TIMeta?.name ?? "locale-user-account",
    path: indexY0bHbiu1TIMeta?.path ?? "/:locale/user-account",
    meta: indexY0bHbiu1TIMeta || {},
    alias: indexY0bHbiu1TIMeta?.alias || [],
    redirect: indexY0bHbiu1TIMeta?.redirect,
    component: () => import("/usr/src/app/pages/user-account/index.vue").then(m => m.default || m)
  },
  {
    name: warrantyp0guaxt5CBMeta?.name ?? "locale-warranty",
    path: warrantyp0guaxt5CBMeta?.path ?? "/:locale/warranty",
    meta: warrantyp0guaxt5CBMeta || {},
    alias: warrantyp0guaxt5CBMeta?.alias || [],
    redirect: warrantyp0guaxt5CBMeta?.redirect,
    component: () => import("/usr/src/app/pages/warranty.vue").then(m => m.default || m)
  }
]